<script setup>
import {Swiper, SwiperSlide} from 'swiper/vue'

const {bannerSlidesToShow} = useRuntimeConfig().public

const props = defineProps({
  events: {
    type: Array,
    default: () => [],
  },
  itemsToShow: {
    type: Number,
  },
  breakpoints: {
    type: Object,
    default: () => ({
      768: {
        slidesPerView: 3,
        spaceBetween: 15,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 15,
      },
      1400: {
        slidesPerView: 4,
        spaceBetween: 15,
      },
    }),
  },
})

const itemsToShow = props.itemsToShow || bannerSlidesToShow

const eventSlider = ref(null)
const swiperInstance = ref(null)

const swiperItemsCount = computed(() => {
  return swiperInstance.value?.slides.length
})

const showNavigation = computed(() => {
  if (swiperInstance.value === null) {
    return props.events.length > itemsToShow
  }

  const currentBreakpoint = swiperInstance.value.currentBreakpoint
  if (
    currentBreakpoint &&
    props.breakpoints &&
    props.breakpoints[currentBreakpoint] &&
    props.breakpoints[currentBreakpoint].slidesPerView
  ) {
    return swiperItemsCount.value > props.breakpoints[currentBreakpoint].slidesPerView
  }
  return swiperItemsCount.value > itemsToShow
})

onMounted(() => {
  swiperInstance.value = eventSlider.value.$el.swiper
})

const slideNext = () => {
  swiperInstance.value.slideNext()
}

const slidePrev = () => {
  swiperInstance.value.slidePrev()
}

const onEventCardClick = (event) => {}
</script>

<style lang="scss">
@import 'assets/css/components/global/nu-event-slider.css';
</style>

<template>
  <div class="nu-event-slider-wrapper d-none d-md-block">
    <swiper
      :breakpoints="breakpoints"
      :navigation="false"
      :slides-per-view="itemsToShow"
      :space-between="15"
      id="eventSlider"
      ref="eventSlider"
    >
      <swiper-slide
        :key="index"
        v-for="(event, index) in events"
      >
        <NuEventCard
          :event="event"
          @event-card-click="onEventCardClick"
        />
      </swiper-slide>
    </swiper>

    <div
      class="navigation"
      v-if="showNavigation"
    >
      <div
        class="navigation-prev"
        @click="slidePrev"
      >
        <span class="navigation-icon">
          <font-awesome-icon :icon="['fas', 'circle-chevron-left']" />
        </span>
      </div>
      <div
        class="navigation-next"
        @click="slideNext"
      >
        <span class="navigation-icon">
          <font-awesome-icon :icon="['fas', 'circle-chevron-right']" />
        </span>
      </div>
    </div>
  </div>
  <div class="d-flex flex-column d-md-none gap-2 d-flex">
    <NuEventCard
      :event="event"
      :key="index"
      v-for="(event, index) in events"
    />
  </div>
</template>
<style lang="scss"></style>
